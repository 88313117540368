@import '../../css/base-color';
.card-detail {
  display: flex !important;
  flex-direction: row !important;
  border-radius: 0px 0px 8px 8px !important;
  .photo-image {
    border-radius: 0px 0px 0px 8px !important;
    border: none;
    border-radius: 0;
    width: 160px;
    height: 160px;
  }
  .card-body {
    .card-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
    .card-context {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: $grey-color;
      display: flex;
      gap: 5px;
    }
    .card-button-context {
      display: flex;
      gap: 16px;
    }

    .badge {
      margin-bottom: 3px;
    }
  }
}
