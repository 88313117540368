.tips-new-detail {
  display: flex;
  flex-direction: column;
  .tips-detail-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    gap: 16px;
    background: white;
  }
  .tips-detail-data-context {
    display: flex;
    gap: 80px;
    .tips-detail-data {
      .tips-photos {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .tips-photos-context {
          display: flex;
          gap: 16px;
          .tips-photos-maps {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .tips-photos-element {
              cursor: pointer;
              width: 140px;
              height: 140px;
              object-fit: cover;
            }
            .tips-button-remove {
              width: 140px;
            }
          }
        }
        .tips-button-upload {
          width: 140px;
        }
      }
    }
  }
}
