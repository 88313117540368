.rental-detail {
  display: flex;
  flex-direction: column;
  .rental-detail-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    gap: 16px;
    background: white;
  }
  .rental-detail-data-context{
    display: flex;
    gap: 80px;
  }
}
