@import '../../css/base-color';
.table-choice-detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .header-context {
    display: flex;
    align-items: center;
    gap: 15px;
    .header-table {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
    .table-button {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .table-description {
    color: $grey-color;
    .table-modal-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .table-context {
    .no-change-table {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $grey-color;
    }
    .table {
      max-width: 520px;
      padding: 1px 4px 1px 4px;
      border: 1px solid #0000002d;
      border-radius: 8px;

      .button-detail {
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $blue-color;
      }
      .button-delete {
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $red-color;
      }
    }
    .button-order-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      .button-ordering {
        text-decoration: none;
        padding: 0px 8%;
        margin: 0;

        font-size: 24px;
        font-weight: 800;
      }
    }
  }
}
