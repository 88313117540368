@import '../../../css/screen-size';
.airline-detail {
  display: flex;
  flex-direction: column;
  .airline-detail-context {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    gap: 16px;
    background: white;
    .item-data {
      display: flex;
      flex-direction: column;
      width: 100%;
      .airline-data {
        display: flex;
        gap: 50px;
      }
    }
  }
  .header {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .header-cards {
    width: 100%;
    border: 0px 0px 1px 0px;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 16px 8px 16px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03));

    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .airline-active {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: $desktop-screen-width) {
  .airport-detail {
    .airport-detail-context {
      .item-data {
        .airport-data {
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }
}
