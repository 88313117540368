$light-grey-color: #adb5bd;
$border-grey-color: #ced4da;
$white-color: #e9ecef;
$description-gray-color: #f8f9fa;
$grey-color: #565e64;
$dark-grey-color: #6c757d;
$violet-color: #6610f2;
$green-color: #146c43;
$green-color-background: #1aa179;
$light-green-color-background: #a6e9d5;
$black-color: #212529;
$black-color-blur: #21252940;
$red-color: #dc3545;
$blue-color: #0d6efd;
$dark-blue-color: #0a53be;
$orange-color: #CA6510;

$time-green-color: #198754;
$time-orange-color: #fd7e14;
$time-red-color: #b02a37;
