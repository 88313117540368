.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;
  gap: 4px;
  .filter-header {
    display: flex;
    font-weight: 700;
    text-align: center;
  }
  .filter-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .filter-input {
      display: flex;
      justify-content: space-between;
    }
  }
}
