.breadcrumb-context {
  margin-top: 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 5px;
  .breadcrumb {
    --bs-breadcrumb-margin-bottom: 0 !important;
  }
  .breadcrumb-back {
    cursor: pointer;
    display: flex;
    align-items: center;
    .breadcrumb-image {
      width: 14px;
      height: 8px;
    }
  }
  .breadcrumb-items {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.cards-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}
