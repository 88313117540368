@import '../../css/base-color';

.sort-filter-context {
  margin-bottom: 6px;
  .search-context {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    gap: 8px;
    .search-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .search-input {
        width: 222px;
        height: 31px;
        border-radius: 3px 0px 0px 3px;
        padding: 4px 8px 4px 8px;
        border: 1px solid $border-grey-color;

        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
      .search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 31px;
        padding: 4px 8px 4px 8px;
        border-radius: 0px 3px 3px 0px;
        border: 1px solid $blue-color;
        gap: 10px;
      }
    }

    .sort-filter-search {
      display: flex;
      align-items: center;
      .search-button {
        text-decoration: none;
      }
    }
  }
}
.search-overlay-context {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 8px;
  padding: 8px;
  background: white;
  .filter-context {
    display: flex;
    flex-direction: column;
    .modal-window-select {
      > button {
        width: 90px;
        padding: 0px 8px;
      }
    }
  }
  .button-search {
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
  }
}
.popover {
  --bs-popover-zindex: 1050 !important;
}
