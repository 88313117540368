@import '../css/base-color';
.modal-window-confirm{
  display: flex !important;
  align-items: center;
  justify-content: center;
  .modal-dialog{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    .modal-content{
      height: auto;
      min-height: auto;
    }
  }
}

.modal-context {
  .red-button {
    color: $red-color;
  }
}