.tips-detail {
  display: flex;
  flex-direction: column;
  .tips-detail-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    gap: 16px;
    background: white;
  }
  .tips-detail-data-context {
    display: flex;
    gap: 80px;
    .tips-photos {
      display: flex;
      gap: 100px;
      .tips-photos-context {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        .tips-photos-element {
          cursor: pointer;
          object-fit: cover;
          width: 104px;
          height: 104px;
        }
      }
    }
  }
  .tips-linked-header {
    font-family: Helvetica Neue;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .tips-linked {
    margin-top: 16px;
    padding: 8px 12px;
    border-radius: 16px;
    background-color: #ced4da;
    border: 1px solid #0000002d;
  }
  .action-button {
    display: flex;
    gap: 8px;
    .approved-button {
      width: 120px;
    }
    .rejected-button {
      width: 120px;
    }
    .review-button{
      width: 100%;
    }
  }
}

.tips-photos-modal{
  height: 70vh;
}