@import '../../../../css/base-color';
.airport-id-detail {
  display: flex;
  justify-content: space-between;
  .lat-lon-context {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .id-context {
    display: flex;
    gap: 10px;
  }
  .airport-id-context {
    display: flex;
    flex-direction: column;
    .name {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: $black-color;
    }
    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: $grey-color;
    }
    .timezone {
      font-size: 12px;
      font-weight: 700;
      color: $black-color;
    }
  }
  .map-button {
    width: 91.6px;
    height: 20.4px;
    padding: 4.2px 7.8px 4.2px 7.8px;
    border-radius: 100px;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
  }
  .right {
    text-align: right;
  }
}
