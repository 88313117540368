@import '../css/base-color';
.weather-page {
  width: 100%;
  min-height: 100vh;
  .navbar-info {
    width: 100%;
    height: 80px;
    padding: 16px;
    position: fixed;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    background: $white-color;
    gap: 15px;
  }
  .fake-navbar-info {
    height: 90px;
  }
  .weather-tabs-context {
    width: 800px;
    .weather-tabs-id {
      width: 60px;
    }
    .weather-tabs-name {
      width: 300px;
    }
    .weather-tabs-description {
      .weather-language-input {
        width: 100%;
        padding-left: 10px;
      }
    }
  }
}
