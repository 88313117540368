.pagination-page {
  width: 100%;
  height: 100%;
  .pagination {

    height: 100px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    bottom: 0;
    transform: translateX(-50%);
  }
  .pagination-context {
    display: flex;
    justify-content: center;

    .loading-context {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50vh;
      .loading {
        width: 50px;
        height: 50px;
      }
    }
  }
}
