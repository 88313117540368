@import '../css/base-color';
.cards-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 100px;
  .card {
    width: 230px;
    cursor: pointer;
    .card-image {
      width: 100%;
      height: 152px;
    }
    .card-image-fill {
      object-fit: scale-down;
    }
    .card-time-info {
      position: absolute;
      display: flex;
      top: 125px;
      left: 8px;
      background: $grey-color;
      color: $description-gray-color;
      height: 20px;
      padding: 4px 6px 4px 6px;
      border-radius: 4px;
      gap: 4px;

      font-size: 12px;
      line-height: 12px;
      .time-info-head {
        font-weight: 400;
      }
      .time-info-desc {
        --bs-badge-padding-x: none;
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
      }
    }
    .card-time-green {
      background: $time-green-color;
    }
    .card-time-yellow {
      background: $time-orange-color;
    }
    .card-time-red {
      background: $time-red-color;
    }
    .card-body {
      .card-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }
      .card-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-color;
      }
    }
  }
}
