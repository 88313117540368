@import '../css/base-color';
.navbar-component {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $black-color-blur;
  margin: 8px 20px 0px 15px;
  width: 100%;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  .navbar-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: nowrap !important;
    .navbar-collapse{
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
    }

    .header {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: $black-color;
    }
    .add-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      padding: 0;
      .add-button-image {
        margin-left: 8px;
        padding: 5px;
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
    .search-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .search-input {
        width: 222px;
        height: 31px;
        border-radius: 3px 0px 0px 3px;
        padding: 4px 8px 4px 8px;
        border: 1px solid $border-grey-color;

        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
      .search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 31px;
        padding: 4px 8px 4px 8px;
        border-radius: 0px 3px 3px 0px;
        border: 1px solid $blue-color;
        gap: 10px;
      }
    }
    .sort-search {
      margin-left: 29px;
    }
    .filter-search {
      margin-left: 15px;
    }
  }
  .sort-search {
    .sort-filter-name {
      display: flex;
      align-items: center;
      gap: 8px;
      .text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: gray;
      }
      .active {
        color: $blue-color;
        border-bottom: 1px solid $blue-color;
      }
      .button-clear-search-params {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 6px;

        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
}
.sort-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px 16px;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  .filter-sort-content {
    display: flex;
    gap: 30px;
    .inline-content {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
  .button-search {
    display: flex;
    justify-content: center;
    gap: 8px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
