.push-event-page-context {
  padding: 24px 16px 0px 16px;
  .push-event-page {
    display: flex;
    flex-direction: column;
    .add-button{
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap:8px;
    }
    .table-push-event {
      display: flex;
      flex-direction: column;
      width: 800px;
      border-radius: 8px;
      border: 1px solid #0000002d;
      overflow: hidden;
      .table-push-thead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid black;

        .table-push-thead-header {
          width: 266px;
          padding: 8px;

          font-family: Roboto;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          background: white;
        }
      }
      .table-push-tbody {
        .table-items-context {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: white;
          .table-items {
            width: 266px;
            padding: 8px;

            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            .table-items-button {
              padding: 0px;
              text-decoration: none;

              font-family: Roboto;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
        .table-color-gray {
          background: #ffffff00;
        }
      }
    }
  }
}
