@import '../../../css/base-color';
.airport-map-detail {
  display: flex;
  flex-direction: column;
  .airport-map-detail-context {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    gap: 16px;
    background: white;
    .context {
      display: flex;
      flex-direction: column;
      .image-context-button {
        display: flex;
        gap: 30px;
        .photo {
          display: flex;
          .head {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
          .necessarily {
            font-family: Helvetica Neue;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $red-color;
          }
        }
        .button-context {
          display: flex;
          gap: 8px;
          .button-upload {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 88px;
            height: 31px;
            gap: 8px;

            font-family: Helvetica Neue;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            > svg {
              width: 16px;
              height: 14px;
            }
          }
          .button-unsplash {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 31px;
            gap: 5px;

            > svg {
              width: 17px;
              height: 17px;
            }
          }
        }
      }
      .image {
        margin-top: 10px;
        width: 289px;
        height: 220px;
        margin-bottom: 10px;
      }
    }
  }
}
