.modal-content {
  min-height: 90vh;
  .location {
    //styleName: Body/Small;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .modal-body {
    position: relative;
  }
  .google-map-location {
    width: 100%;
    height: 65vh;
    > div {
      > div {
      }
    }
  }
}
.google-maps {
  display: flex;
  align-items: center;
  .google-maps-button {
    width: 91.6px;
    height: 20.4px;
    padding: 4.2px 7.8px 4.2px 7.8px;
    border-radius: 100px;

    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
  }
}
