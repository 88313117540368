.authorization {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  .authorization-context {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    background-color: white;
    border-radius: 20px;
    padding: 25px;
    width: 100%;
    .label {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .controller {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
    }
    .button {
      height: 40px;
      font-size: 20px;
      font-weight: 700;
      line-height: 21px;
    }
  }
}
