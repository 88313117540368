.push-event-detail {
  display: flex;
  flex-direction: column;
  .push-event-detail-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    gap: 16px;
    background: white;
    .push-event-header {
      margin-bottom: 24px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #6c757d;
    }
  }
}
