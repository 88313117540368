@import '../../css/base-color';
.header-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .header-data-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    .header {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
    .button-context {
      display: flex;
      align-items: center;
      gap: 8px;
      .save-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 31px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
      .delete-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 105px;
        height: 31px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }

      .checked {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: white;
        gap: 8px;
        height: 29px;
        padding: 4px 8px 4px 8px;
        border-radius: 3.2px;

        > svg {
          > g {
            > path {
              fill: white;
            }
          }
        }
        .checked-image {
          width: 14px;
          height: 16px;
        }
      }

      .mark-check {
        background: $time-green-color;
        border-color: $time-green-color;
        > svg {
          > g {
            > path {
              transition: fill 0.15s ease-in-out;
            }
          }
        }
        &:hover {
          background: white;
          color: $time-green-color;
          > svg {
            > g {
              > path {
                transition: fill 0.15s ease-in-out;
                fill: $time-green-color;
              }
            }
          }
        }
      }
      .success-check {
        background: $time-green-color;
        border-color: $time-green-color;
      }
      .alert-last-checked {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 12px;
        height: 29px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 0;
      }
    }
  }
  .header-user-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    .user-last-update {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      color: $light-grey-color;
    }
    .user-last-green {
      color: $time-green-color;
    }
    .user-last-yellow {
      color: $time-orange-color;
    }
    .user-last-red {
      color: $time-red-color;
    }
    .user-name {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px 8px 1px 8px;
      border-radius: 4px;
      gap: 4px;
      background: $white-color;

      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      > svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
