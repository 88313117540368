@import '../css/base-color';
.tips-page-context {
  padding: 24px 16px 0px 16px;
  .tips-page {
    display: flex;
    flex-direction: column;
    .add-button {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .table-push-event {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 1100px;
      border-radius: 8px;
      border: 1px solid #0000002d;
      overflow: hidden;
      .table-push-thead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid black;
        background: white;

        .table-push-thead-header {
          box-sizing: border-box;
          width: 266px;
          padding: 8px;

          font-family: Roboto;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
      }
      .table-push-tbody {
        .table-items-context {
          display: flex;
          justify-content: space-between;
          background: white;
          .table-items {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            width: 266px;
            padding: 8px;

            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            .table-item-date {
              color: $dark-grey-color;
            }
            .table-item-iata {
              color: $black-color;
            }
            .table-item-text {
              color: $black-color;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .table-item-prewImage {
              display: flex;
              gap: 8px;
              .table-item-image {
                width: 52px;
                height: 52px;
              }
            }

            .table-item-raiting {
              display: flex;
              align-items: center;
              gap: 10px;
              .like {
                display: flex;
                align-items: center;
                > svg {
                  width: 24px;
                  height: 24px;
                }
              }
              .dislike {
                display: flex;
                align-items: center;
                > svg {
                  width: 24px;
                  height: 24px;
                }
              }
            }

            .table-items-button {
              padding: 0px;
              text-decoration: none;

              font-family: Roboto;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
            .button-delete {
              color: $red-color !important;
            }
          }

          .status-approved {
            color: $time-green-color !important;
          }

          .status-in-review {
            color: $orange-color !important;
          }

          .status-rejected {
            color: $red-color !important;
          }
        }
        .table-color-gray {
          background: #ffffff00;
        }
      }
    }
    .t-date {
      width: 150px !important;
    }
    .t-iata {
      width: 100px !important;
    }
    .t-text {
      width: 400px !important;
    }
    .t-category {
      width: 120px !important;
    }
    .t-status {
      width: 120px !important;
    }
    .t-action {
      width: 120px !important;
    }
  }
}

.modal .modal-head .btn-close {
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.tips-modal-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tips-modal-head {
    font-family: Helvetica Neue;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .tips-modal-date {
    //styleName: Body/Regular;
    font-family: Helvetica Neue;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $dark-grey-color;
  }
}
