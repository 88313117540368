@import '../../css/base-color';
.block-detail {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $black-color-blur;
  padding-bottom: 16px;
  margin-bottom: 14px;
  .context {
    display: flex;
  }
}
