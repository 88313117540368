@import '../../css/base-color';
.table-detail-choice {
  .add-header {
    display: flex;
    align-items: center;
    gap: 10px;
    .header {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .nav-bar-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table-context {
    background: white;
    padding: 20px;
    border-radius: 8px;
    gap: 24px;

    .table-detail-context {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .add-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-color;
      }
      .table-choice {
        max-width: 520px;
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid #0000002d;
        .table-gray {
          background-color: $border-grey-color;
        }
        .table-context {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 3px 8px;
          border-radius: 0;
          .name-context {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            .name-icon {
              display: flex;
              align-items: center;
              width: 16px;
              height: 16px;
            }
            .name-title {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
        .button-context {
          display: flex;
          align-items: center;
          .selected-button {
            .button-delete {
              text-decoration: none;
              color: $red-color;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
            .button-select {
              text-decoration: none;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
          .divider {
            height: 16px;
            border: 1px solid #21252940;
          }
          .detail-button {
            .modal-button {
              text-decoration: none;
              color: $grey-color;
            }
          }
        }
      }
    }
  }
}
