@import '../../../../css/base-color';
.input-naw-bar-content {
  width: 100%;
  .input-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    .input-naw-bar-header {
      font-size: 14px;
    }
    .input-naw-bar {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      height: 24px;
    }
  }
  .filter-dropdown-content {
    display: flex;
    .filter-dropdown {
      text-decoration: none;
      padding: 0px;
    }
    .active {
      color: $green-color !important;
    }

    .filter-clear-button {
      padding: 0px 4px;
      text-decoration: none;
      color: gray;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
    }
    .active-clear {
      color: $red-color;
    }
  }
  .select-input {
    display: flex;
    align-items: center;
    .modal-window-select {
      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        text-decoration: none;
        padding: 0px;
      }
    }
    .filter-clear-button {
      padding: 0px 4px;
      text-decoration: none;
      color: gray;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
    }
    .active-clear {
      color: $red-color;
    }
  }
}
.selected-element {
  .modal-window-select {
    > button {
      color: $green-color !important;
    }
  }
}
