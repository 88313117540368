@import '../../css/base-color';

.color-picker-detail {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .head-context {
    display: flex;
    width: 150px;
    .head {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .necessarily {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: $red-color;
    }
  }

  .color-picker-content {
    display: flex;
    align-items: center;
    padding: 2px 0px 2px 4px;
    border: 1px solid #ced4da;
    border-radius: 4px 0px 0px 4px;
    .color-picker-box-show {
      width: 32px;
      height: 32px;

      border-radius: 4px;
    }
    .input-color-picker {
      border: none;
      background: none;
      height: 32px;
      width: 223px;
    }
  }
  .color-select {
    > button {
      border-radius: 0px 4px 4px 0px;
    }
    .color-select-content {
      position: absolute;
      box-sizing: border-box;
      gap: 12px;
      width: 196px;
      height: 175px;
      padding: 8px 16px 16px 16px;
      border-radius: 8px;

      box-shadow: 0px 8px 16px 0px #00000026;
      background: white;
      .color-select-header {
      }
      .color-select-modal {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        > button {
          width: 32px;
          height: 32px;
          border-radius: 4px;
        }
      }
    }
  }

  .color-picker-button {
    margin-left: 15px;
    > button {
      width: 120px;
      height: 38px;
      padding: 0px 8px 0px 8px;
    }
    .color-picker-content {
      position: absolute;
      box-sizing: border-box;
      box-shadow: 0px 8px 16px 0px #00000026;
      border-radius: 8px;
    }
  }
}
