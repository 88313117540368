.public-transport-detail {
  display: flex;
  flex-direction: column;
  .public-transport-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    gap: 16px;
    background: white;
  }
  .public-transport-data-context {
    display: flex;
    gap: 80px;
  }
  .public-transport-block-header {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .public-transport-block-moadal-image{
    margin-bottom: 10px;
  }
  .public-transport-cost {
    display: flex;
    align-items: center;
    gap: 8px;
    .public-transport-price-fixed {
      display: flex;
      gap: 8px;
      margin-bottom: 16px;
      .public-transport-price-fixed-checkbox{

      }
    }
  }
  .public-transport-price {
    display: flex;
    gap: 16px;
    > div {
      > div {
        > div {
          > input {
            width: 70px;
          }
        }
      }
    }
  }
}