@import '../../css/base-color';

.input-detail {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .input-lang-wrapper {
    display: flex;
    align-items: flex-start;
    .select-website {
      width: 34px;
      height: 38px;
      padding: 4px 8px 4px 8px;
      border-radius: 0px 3.2px 3.2px 0px;
      border: 1px solid $border-grey-color;
      gap: 8px;
      background: linear-gradient(0deg, $white-color, $white-color),
        linear-gradient(0deg, $border-grey-color, $border-grey-color);
    }
  }

  .head-context {
    display: flex;
    width: 150px;
    .head {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .necessarily {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: $red-color;
    }
  }
}
.subtitle { 
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 70px;
  height: 38px;
  padding: 4px 8px 4px 8px;
  border-radius: 3.2px 0px 0px 3.2px;
  border: 1px solid $border-grey-color;
  background: $white-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $black-color;
}

.dropdown-website {
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 94px;
    height: 38px;
    padding: 4px 8px 4px 8px;
    border-radius: 3.2px 0px 0px 3.2px;
    border: 1px solid $border-grey-color;
    background: linear-gradient(0deg, $white-color, $white-color),
      linear-gradient(0deg, $border-grey-color, $border-grey-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $black-color;

    &:hover,
    &:active {
      color: $black-color !important;
    }
  }
}
.dropdown-language {
  > button {
    height: 31px;
    padding: 4px 8px 4px 8px;
    border-radius: 3.2px;
    border: 1px;
    gap: 3.5px;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
}
