@import '../../../css/base-color';
.sort-content {
  display: flex;
  padding: 4px 0px;
  .sort-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    padding: 0px;
    &:hover {
      .sort-description {
        > svg {
          > path {
            stroke: #0a58ca;
          }
        }
      }
    }
  }
  .btn.show {
    .sort-description {
      > svg {
        > path {
          stroke: #0a58ca;
        }
      }
    }
  }
  .active-sort {
    color: $green-color;
    .sort-description {
      > svg {
        > path {
          stroke: $green-color;
        }
      }
    }
  }
  .dropdown-menu {
    gap: 8px;
    left: -40px !important;
  }
  .dropdown-menu-context {
    display: flex;
    .dropdown-menu-button {
      display: flex;
      align-items: center;
      padding: 0px 4px;
    }
  }
  .sort-description {
    display: flex;
    align-items: center;
    > svg {
      width: 16px;
      height: 16px;
    }
  }
  .sort-disabled{
    > svg {
      >path{
        stroke: $grey-color;
      }
    }
  }
  .filter-clear-button {
    padding: 0px 4px;
    text-decoration: none;
    color: gray;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
  }
  .active-clear {
    color: $red-color;
  }
}

.reverse {
  > svg {
    transform: rotate(180deg);
  }
}
