@import '../../../css/base-color';
.filter-boolean {
  display: flex;
  align-items: center;
  .filter-button {
    padding: 0;
    text-decoration: none;
  }
  .active {
    color: $green-color !important;
  }
  .filter-clear-button {
    padding: 0px 4px;
    text-decoration: none;
    color: gray;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
  }
  .active-clear {
    color: $red-color;
  }
}
