@import '../css/base-color';
@import '../css/screen-size';
.tab-controller {
  display: flex;
  width: 100%;
  .tab-context {
    width: 250px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 16px;
    height: 100vh;
    gap: 4px;
    left: 0;
    top: 0;
    background: white;
    .logotype-context {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .open-button {
        border: none;
        background: none;
        padding: 0;
        > svg {
          width: 18px !important;
          height: 12px !important;
        }
      }
      .logo {
        width: 84px;
        height: 24px;
      }
      .close-button {
        width: 16px;
        height: 16px;
      }
    }
    .tab-element {
      .block-detail {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 4px;
      }
      .divider{
        width: 100%;
        border-bottom: 1px solid #21252940;
      }
      .element-context {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        border: none;
        height: 36px;
        > svg {
          > path {
            transition: fill 0.15s ease-in-out;
          }
          > g {
            > path {
              transition: fill 0.15s ease-in-out;
            }
          }
          > rect{
            transition: fill 0.15s ease-in-out;
          }
        }
        &:hover {
          background: $dark-blue-color;
          > svg {
            > path {
              fill: white !important;
            }
            > g {
              > path {
                fill: white !important;
              }
            }
            > rect{
              fill: white !important;
            }
          }
        }
      }
      .localization-dropdown-menu {
        --bs-dropdown-min-width: none;
      }
      .active-link {
        background: $blue-color !important;
        border: none !important;
        color: white !important;
        > svg {
          > path {
            fill: white !important;
          }
          > g {
            > path {
              fill: white !important;
            }
          }
        }
        &:hover {
          background: $dark-blue-color !important;
          > svg {
            > path {
              fill: white !important;
            }
            > g {
              > path {
                fill: white !important;
              }
            }
          }
        }
      }
    }
    .show {
      > svg {
        > path {
          fill: white !important;
        }
        > g {
          > path {
            fill: white !important;
          }
        }
      }
    }
  }
  .close {
    width: 80px !important;
  }
  .log-out {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $black-color-blur;
    box-sizing: border-box;
    padding-top: 16px;
    .language-description {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 999px;

      font-family: Helvetica Neue;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      color: $green-color-background;
      background: $light-green-color-background;
    }
    .log-out-button {
      cursor: pointer;
      padding: 0px 8px;
      border: 0;
      > svg {
        width: 16px !important;
        height: 16px !important;
        > path {
          transition: stroke 0.15s ease-in-out;
        }
      }
      &:hover {
        > svg {
          > path {
            stroke: white;
          }
        }
      }
    }
  }
  .center-context {
    justify-content: center !important;
    align-items: center;
  }
  .fake-tabs-context {
    min-width: 260px;
    max-width: 260px;
  }
  .fake-tabs-context-close {
    min-width: 95px;
    max-width: 95px;
  }
  .context {
    width: 100%;
    padding-right: 16px;
  }
}

.tab-controller:has(.authorization) {
  .fake-tabs-context {
    display: none;
  }
  .fake-tabs-context-close {
    display: none;
  }
  .tab-context {
    display: none;
  }
  .context {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: $desktop-screen-width) {
  .tab-controller {
    .tab-context {
      width: 200px;
      .logotype-context {
        .logo {
          width: 70px;
          height: auto;
        }
        .close-button {
          width: 12px;
          height: 12px;
        }
      }
    }
    .fake-tabs-context {
      min-width: 215px;
      max-width: 215px;
    }
    .context {
      width: 100%;
    }
  }
}
