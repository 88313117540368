@import '../css/base-color';
.input {
  width: 330px;
}
.website {
  width: 204px;
  height: 38px;

  border: 1px solid $border-grey-color;
}
.textarea {
  border: 1px solid black;
}
