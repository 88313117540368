@import './Components/css/screen-size';
@import './Components/css/base-color';

.main-page {
  overflow: hidden;
  width: 100%;
}

.incorrect-border {
  border: 3px rgba(255, 0, 0, .5) solid;
}

.controller {
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: $white-color;
}
