@import '../../css/base-color';
.description-image-context {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .description-image-upload {
    > button {
      width: 200px;
    }
  }
  .description-mass-image {
    display: flex;
    flex-direction: column;
    gap:8px;
    .image-content {
      .desctiption-image {
        width: 200px;
      }
    }
  }
}
