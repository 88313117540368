@import '../../css/base-color';

.input-drop-down {
  display: flex;
  margin-bottom: 16px;
  .head-context {
    display: flex;
    width: 150px;
    .head {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .necessarily {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: $red-color;
    }
  }
  .drop-down-language {
    > button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 31px;
      width: 330px;
      padding: 4px 8px 4px 8px;
      border-radius: 3.2px;
      border: 1px;
      gap: 3.5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      background: $white-color;
      border: 1px solid $border-grey-color;

      &::after {
        align-items: flex-end;
      }
    }
  }
}
