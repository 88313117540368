.pagination-item {
  border-radius: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    border-radius: 0px !important;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    border-radius: 0px !important;
  }
}
.pagination-item-navigate {
  border-radius: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  a {
    width: 30px;
    border-radius: 0px !important;
  }
  span {
    width: 30px;
    border-radius: 0px !important;
  }
}
