.modal-context {
  .modal-window {
    width: 100%;
  }
}
.modal {
  --bs-modal-width: 90% !important;

  .modal-head {
    display: flex;
    align-items: flex-start;
    width: 100%;
    .modal-title {
      width: 100%;
    }    
    .btn-close {
      margin-top: 20px;
      
    }
  }
  .modal-body {
    display: flex;
    .modal-context {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: 70vh;
      gap: 15px;
    }
    .context {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      .cards-modal {
        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        width: 180px;
      }
    }
  }
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-button {
      width: 150px;
    }
  }
}
