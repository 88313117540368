.pin {
  display: flex;
  align-items: flex-start;
  margin-left: -15px;
  margin-top: -50px;
  gap: 5px;
  .pin-marker {
    width: 30px;
  }
  .pin-text {
    position: absolute;
    width: 250px;
    left: 20px;
    padding: 8px;
    box-sizing: border-box;
    background: white;
    border-radius: 10px;
    border: 1px solid;

    font-family: Helvetica Neue;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
