.map-category-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  .category {
    border: gray 1px solid;
    padding: 5px;
    display: flex;
    flex-direction: column;

    span {
      font-size: 20px;
      font-weight: 600;
    }

    .red-button {
      color: #dc3545;
    }

    .button-maps-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      .button-maps-ordering {
        text-decoration: none;
        padding: 0px 8%;
        margin: 0;

        font-size: 24px;
        font-weight: 800;
      }
    }

    .category-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 5px 0;
    }

    .buttons {
      display: flex;
      gap: 10px;
    }
  }

  .add-new {
    display: flex;
    gap: 10px;
    .add-btn {
    }
  }
}
