@import '../../css/base-color';

.input-markdown{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  .md-editor{
    max-width: calc(100vw - 360px) !important;
  }
  .md-editor-content{
    width: 100% !important;
  }
}