@import '../css/base-color';
.image-context {
  margin-bottom: 16px;
  .image-photo-description {
    display: flex;
    .head {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .necessarily {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $red-color;
    }
  }
  .button-context {
    display: flex;
    gap: 8px;
    .button-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 88px;
      height: 31px;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      > svg {
        width: 16px;
        height: 14px;
      }
    }
    .button-unsplash {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 31px;
      gap: 5px;

      > svg {
        width: 17px;
        height: 17px;
        > path {
          transition: fill 0.15s ease-in-out;
        }
      }
      &:hover {
        > svg {
          > path {
            fill: white !important;
          }
        }
      }
    }
    
    .delete-button-image{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 31px;
    }
  }
  .image {
    cursor: pointer;
    margin-top: 16px;
    width: 104px;
    height: 104px;
  }
}
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .tab-selected-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tab-unsplash-api {
    display: flex;
    width: 80vw;
    height: 70vh;
    .unsplash-react {
      width: 100%;
    }
  }
  .button-upload {
    width: 100px;
  }
  .modal-image {
    max-width: 100%;
    height: 60vh;
  }
}
